<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-08-12 09:55:54
-->
<template>
	<div class="activityList">
		<el-tabs v-model="currentTab" @tab-click="changeTab">
			<el-tab-pane label="全部" name="ALL"></el-tab-pane>
			<el-tab-pane label="审核中" name="UNDER_REVIEW"></el-tab-pane>
			<el-tab-pane label="进行中" name="PROCESSING"></el-tab-pane>
			<el-tab-pane label="已完成" name="FINISHED"></el-tab-pane>
			<el-tab-pane label="未通过" name="DID_NOT_PASS"></el-tab-pane>
		</el-tabs>
		<div class="activityList-data">
			<div class="common-row">
				<div class="common-row-item">
					<span class="common-row-label">活动名称</span>
					<el-input class="common-row-ipt" placeholder="请输入活动名称" v-model="searchForm.name" />
				</div>
				<div class="common-row-item">
					<span class="common-row-label">活动时间</span>
					<el-date-picker class="common-row-ipt" :clearable="false" value-format="yyyy-MM-dd"
						@change="dateChange" v-model="searchForm.time" type="daterange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期">
					</el-date-picker>
				</div>
				<div class="common-row-item">
					<el-button @click="searchFn" type="primary">查询</el-button>
					<el-button @click="resetFn">重置</el-button>
				</div>
			</div>
			<el-table :data="tableData" border>
				<el-table-column prop="id" label="活动ID">
				</el-table-column>
				<el-table-column prop="activityName" label="活动名称">
				</el-table-column>
				<el-table-column width="250px" prop="type" label="活动时间">
					<template slot-scope="scope" v-if="scope.row.starTime&&scope.row.endTime">
						<div class="table-action-date">
							<span>{{`${dateFormat(scope.row.starTime)} 至 ${dateFormat(scope.row.endTime)}`}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column width="200px" prop="activityStatus" label="活动状态">
					<template v-if="scope.row.activityStatus*1 >= 0" slot-scope="scope">
						<div v-if="scope.row.activityStatus == 2" class="table-action">
							<span @click="actionFn('upload', scope)"
								:class="{'table-action-status_orange': scope.row.workingStatus == 1,'table-action-status_red': scope.row.workingStatus == 2,'table-action-status_green': scope.row.workingStatus == 3}">{{scope.row.workingStatus == 1 ?"工时表待提交":scope.row.workingStatus == 2 ?"工时表超时未提交":"工时表已提交"}}</span>
						</div>
						<div v-else class="table-action">
							<span
								:class="{'table-action-status_black': scope.row.activityStatus != 0,'table-action-status_red': scope.row.activityStatus == 0}">{{scope.row.activityStatus == 0?"未通过":scope.row.activityStatus == 1?"审核中":scope.row.activityStatus == 2?"进行中":"已完成"}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="activityType" label="活动分类">
					<template slot-scope="scope">
						<span>{{scope.row.activityType == 3?"B类":"A类"}}</span>
					</template>
				</el-table-column>
				<el-table-column width="280px" prop="organizer" label="组织名称">
				</el-table-column>
				<el-table-column prop="action" label="操作">
					<template slot-scope="scope">
						<div class="table-action">
							<span v-if="scope.row.activityStatus <= 1 && userId === scope.row.createId" @click="actionFn('edit', scope)">编辑</span>
							<!-- <el-divider v-if="scope.row.activityStatus <= 1" direction="vertical"></el-divider> -->
							<span v-else @click="actionFn('detail', scope)">详情</span>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div class="common-pageNation">
				<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
					:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import storage from 'store';
	import {
		getActivityList,
		editActivityEnable,
	} from "@/api/activity";
	export default {
		data() {
			return {
				currentTab: "ALL",
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				tableData: [],
				userId:'',
				searchForm: {
					name: "",
					time: ""
				},
				starTime: "",
				endTime: ""
			};
		},
		mounted() {
			let userInfo = storage.get('ACCESS_Info');
			this.userId = userInfo.id;
			this.activityList();
		},
		methods: {
			dateFormat(intDate) {
				if (intDate) {
					return intDate.replace(" 00:00:00", "")
				} else {
					return ""
				}
			},
			getEditActivityEnable(id) {
				return new Promise((resolve, reject) => {
					editActivityEnable({
						id: id
					}).then((res) => {
						console.log("getEditActivityEnable", res)
						if (res.code == 0) {
							resolve(res.data)
						} else {
							resolve(true)
						}
					}).catch(err => {
						resolve(true)
					})
				})
			},
			changeTab() {
				this.pageIndex = 1;
				this.activityList();
			},
			searchFn() {
				this.pageIndex = 1;
				this.activityList();
			},
			resetFn() {
				this.pageIndex = 1;
				this.searchForm = {
					name: "",
					time: ""
				};
				this.starTime = "";
				this.endTime = "";
				this.activityList();
			},
			dateChange(value) {
				console.log("dateChange", value);
				if (value) {
					this.starTime = value[0];
					this.endTime = value[1];
				} else {
					this.starTime = "";
					this.endTime = "";
				}
			},
			activityList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
				};
				if (this.searchForm.name) {
					this.$set(params, "activityName", this.searchForm.name)
				}
				if (this.searchForm.time) {
					this.$set(params, "startDay", this.starTime)
					this.$set(params, "endDay", this.endTime)
				}
				if (this.currentTab != 'ALL') {
					this.$set(params, "status", this.currentTab)
				}
				getActivityList(params).then(res => {
					if (res.code == 0) {
						this.tableData = res.data;
						this.totalCount = res.page.totalCount * 1
					}
				});
			},
			currentChange(val) {
				this.pageIndex = val;
				this.activityList();
			},
			async actionFn(type, scope) {
				switch (type) {
					case "edit":
						// await this.getEditActivityEnable(scope.row.id)
						this.$router.push({
							path: `/activityList/edit?activityId=${scope.row.id}`
						});
						break;
					case "detail":
						this.$router.push({
							path: `/activityList/detail?activityId=${scope.row.id}`
						});
						break;
					case "upload":
						if (scope.row.activityStatus == 2 && scope.row.workingStatus == 1) {
							this.$router.push({
								path: `/activityList/detail?activityId=${scope.row.id}`
							});
						}
						break;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	/deep/.el-button {
		height: 36px;
	}
	.activityList {
		.activityList-data {
			display: flex;
			flex-direction: column;
			padding: 0 20px;
			// margin-top: 10px;
			.common-row {
				margin: 16px 0;

				.common-row-item {
					margin-right: 20px;

					.common-row-ipt {
						flex: 1;
						height: 36px;
						line-height: 36px;
						border: 1px solid #edf1f7;
						border-left: 0;
					}
				}
			.table-action-date {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
				span {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 20px;
					color: #303133;
				}
			}

			.table-action {

				.table-action-status_green {
					color: #1CABB9;
				}

				.table-action-status_black {
					color: #303133;
				}

				.table-action-status_orange {
					color: #f5803e;
				}

				.table-action-status_red {
					color: #e63757;
				}
			}
		}
	}
</style>
